function Error404() {
    return (
        <div className="errorPage">
            <div className="container">
                <div className="vertical-center">
                    <h1>404</h1>
                    <p>Sorry! We couldn't find the page you were looking for.</p>
                    <a href="https://joinrealms.com">Back to home</a>
                </div>
            </div>
        </div>
    );
}

export default Error404;